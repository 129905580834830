import React, { useEffect } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import logo from './logo.svg';
import './App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from 'firebase/analytics';
import firebaseConfig from './firebaseConfig';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  useEffect(() => {
    // Ejemplo: Registra un evento personalizado en Firebase Analytics
    logEvent(analytics, 'pagina_inicial', { seccion: 'inicio' });
  }, [analytics]);

  return (
    <div className='App'>
      <div style={{ height: '100vh' }}>
        <Parallax pages={2} style={{ top: '0', left: '0' }} class="animation">
          <ParallaxLayer offset={0} speed={0.3}>
            <div class="animation_layer parallax" id="background"></div>
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.15}>
            <div class="animation_layer parallax " id="logo"></div>
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.3}>
            <div class="animation_layer parallax bold-text" id="element1">
              Himnario app
            </div>
            
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.3}>
            <div class="animation_layer parallax bold-text" id="element2">
              S.C.A. Group System
            </div>
          </ParallaxLayer>
        </Parallax>
      </div>
    </div>
  );
}

export default App;
