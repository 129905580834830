const firebaseConfig = {
  apiKey: "AIzaSyCJcdu0_D-s19SLcBY-lmXh24z6SZNjm6c",
  authDomain: "goland-web.firebaseapp.com",
  projectId: "goland-web",
  storageBucket: "goland-web.appspot.com",
  messagingSenderId: "791932857459",
  appId: "1:791932857459:web:4d635919fd97bb7ee1ce06",
  measurementId: "G-KQT3KLVJ2P"
};

export default firebaseConfig;